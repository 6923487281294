import React from 'react';

const IconDev = () => (
  <svg
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width="32px"
    height="32px">
    <path d="M9,21H6V11h3c1.654,0,3,1.346,3,3v4C12,19.654,10.654,21,9,21z M8,19h1c0.552,0,1-0.448,1-1v-4c0-0.552-0.448-1-1-1H8V19z" />
    <path d="M18,21h-2c-1.103,0-2-0.897-2-2v-6c0-1.103,0.897-2,2-2h2v2h-2v6h2V21z" />
    <rect width="3" height="2" x="15" y="15" />
    <path d="M24.255,11L23,16.439L21.745,11h-2.053l2.083,9.026C21.907,20.596,22.415,21,23,21c0.585,0,1.093-0.404,1.225-0.974 L26.308,11H24.255z" />
    <path d="M2,7v18h28V7H2z M4,9h24v14H4V9z" />
  </svg>
);

export default IconDev;
